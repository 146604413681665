@tailwind base;
@tailwind components;
@tailwind utilities;

input, textarea {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

body {
  background-color: #efefef;
}